body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
  margin-bottom: 0px !important;
}

sup {
  color: #f00;
}

/* Fitbit Charts View(s) */

.container-fluid {
  padding: 0px !important;
  margin: 0px !important;
  max-height: 100%;
}

div.container {
  margin-top: 10px;
}

div.card {
  margin-top: 5px;
}

.m-t5 {
  margin-top: 5px !important;
}

.m-t10 {
  margin-top: 10px !important;
}

.m-t20 {
  margin-top: 20px !important;
}

.m-b5 {
  margin-bottom: 5px !important;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px !important;
}

.m-l15 {
  margin-left: 15px;
}

.m-l30 {
  margin-left: 30px;
}

.m-r5 {
  margin-right: 5px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r10 {
  margin-right: 10px;
}

.m-lr-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t40 {
  margin-top: 40px;
}

.padding-r0 {
  padding-right: 0;
}

.padding-r10 {
  padding-right: 10px;
}

.padding-l0 {
  padding-left: 0;
}

.padding-l10 {
  padding-left: 10px;
}
.padding-l30 {
  padding-left: 30px;
}
.padding-l50 {
  padding-left: 50px;
}

.padding-t20 {
  padding-top: 20px;
}

.padding-b20 {
  padding-bottom: 20px;
}

.padding-t70 {
  padding-top: 70px;
}

.padding-lr-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.width-1100 {
  max-width: 1100px;
}

.width-90vw {
  max-width: 90vw;
}

.w-20 {
  flex: 0 0 20% !important;
  max-width: 20%;
}

.w-19 {
  flex: 0 0 19% !important;
  max-width: 19%;
}

.card-header {
  background-color: #5d5393 !important;
}

div.card {
  margin-bottom: 20px;
}

.d-block {
  color: #ffffff !important;
}

a.list-group-item {
  min-height: 165px;
}

#activity-select,
#summary-select,
.select-custom-class {
  width: 300px;
}

#select-user-select,
#select-group-select {
  width: 100%;
}

#activityChart,
#weightChart,
#heartRateChart,
#sleepChart {
  width: 85vw;
  height: 370px;
}

span.tab-space {
  padding-left: 13.5em;
}

.display-4 {
  font-size: 3rem !important;
}

h1.display-4 {
  display: inline;
}

small {
  color: #666;
}

.btn-outline-secondary {
  color: #666;
  border-width: 2px !important;
}

/* Transitions */
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 5000ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* navbar */
.nav {
  padding-left: 20px !important;
}

.nav-link {
  font-size: 18px;
  color: #000 !important;
}

.nav-link.active {
  background: darkgray;
}

.nav-item {
  margin-right: 30px;
}

.nav-item:last-child {
  margin-right: 0px;
}

.topnav-item {
  color: #fff !important;
}

.login-nav-link {
  color: #000000 !important;
  background: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
}

.login-nav-link.active {
  color: #000000 !important;
  background: white;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  text-decoration: underline;
}

.topnav-item.active {
  color: #fff !important;
  font-weight: 700 !important;
  padding: 5px;
  border-radius: 2px;
  background: #6495ed;
}

.dropdown-toggle.nav-link.white-text {
  color: #fff !important;
}

/* form validation */

.error {
  color: #db2269;
  font-weight: bold;
  display: relative;
}

.small-bold {
  font-weight: 800 !important;
}

/* modified for MBD */
label.mdb-main-label.active {
  font-weight: 800;
  color: black;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.p-list-item {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

label.label-text {
  font-weight: 800;
  color: black;
}

div.modal-dialog.modal-side.modal-bottom-right {
  margin: 125px 0px;
}
.comments-modal {
  overflow: scroll !important;
}

button.btn.btn-default.Ripple-parent.btn-min-padding {
  padding: 5px 10px;
}

button.btn-outline-primary.btn.Ripple-parent.float-right,
a.btn-outline-primary.btn.Ripple-parent.float-right {
  font-size: 16px !important;
}

table thead tr th {
  font-weight: bold;
}

table tbody tr {
  cursor: pointer;
}

/* React Slider in Group Administration */
.rc-slider {
  margin-left: 50px !important;
}

/* Public home */
.homepagebluelogo {
  width: 250px;
}
.fontsizep {
  font-size: 21px;
}
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #ffffff !important;
}

/* DateRangePicker */
div.rdrMonth {
  display: block !important;
}

div.modal-content {
  width: 60vw;
}

select {
  display: block !important;
}

div.rdrInputRanges {
  display: none;
}

button.rdrStaticRange:nth-child(3) {
  display: none !important;
}

button.rdrStaticRange:nth-child(5) {
  display: none !important;
}

/* Sidebar  */
.side-nav .collapsible a {
  font-size: 1.2rem !important;
}

a.Ripple-parent.active {
  background-color: #6495ed !important;
}

#siha-sidebar {
  z-index: 100; /* Behind the navbar */
  padding: 65px 0 0; /* Height of navbar */
}

/* Navbar */
.navbar-nav .dropdown-menu.dropdown-menu {
  position: absolute;
}
.navbar-nav .dropdown-item.dropdown-item,
.navbar-nav .dropdown-item.dropdown-item:hover {
  color: #1e1448;
}

.bg-blue {
  background-color: #0d47a1;
}

.btn-block {
  width: 200px;
  height: 50px;
  font-size: 12px !important;
}

.mp-0 {
  padding: 0px;
  margin: 0px;
}

.userIcon {
  color: #fff;
  font-size: 30px;
}

/* Datatables */

.gv-datatables tfoot {
  display: none;
}

.gv-table-view tfoot {
  display: none;
}

h1 {
  color: black !important;
}

div.dataTables_wrapper div.dataTables_filter input {
  width: 30rem;
}

.devices-alert {
  padding: 0;
}

.pills-rounded .nav-item .nav-link {
  border-radius: 10em;
}

/* ------ Scroll bar ----- */
div.scrollbar.scrollbar-primary.mt-5.mx-auto {
  width: 75vw;
  max-height: 500px;
}
.scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.scrollbar-primary::-webkit-scrollbar {
  background-color: #f5f5f5;
}
.scrollbar-primary {
  scrollbar-color: #4285f4 #f5f5f5;
}
.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285f4;
}

.vertical-timeline-element-icon svg {
  width: 40px !important;
  height: 40px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  top: 0 !important;
  left: 0 !important;
}

.img-fluid {
  max-width: 50%;
}

.tooltipExtraFeature {
  font-size: 20px !important;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #0d47a1;
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: #9ebaec;
}

.fixed-comment-btn {
  position: fixed !important;
  z-index: 998 !important;
  right: 35px !important;
  bottom: 35px !important;
  margin-bottom: 0 !important;
  padding-top: 15px !important;
}

table.table-hover tbody tr:hover {
  background-color: rgba(43, 43, 73, 0.075);
}

#parallaxintro .view {
  min-height: 100vh !important;
  height: unset !important;
}
.fixed-comment-button {
  background: url(/static/media/comments-icon.bee886d3.svg) !important;
  background-size: cover;
}

.fixed-comment-button-disabled {
  background: url(/static/media/comments-icon-disabled.1b3feb1c.svg);
  background-size: cover;
}

hr:last-child {
  display: none;
}

.inputWithButton {
  position: relative;
  height: 100px;
  width: 100px;
}

.input-with-button input {
  width: 90%;
  height: 25px;
  padding-right: 60px;
}

.input-with-button span {
  position: absolute;
  right: 0;
  top: 5px;
}

